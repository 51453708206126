import { pullAt, isObject } from 'lodash';

// we don't use js classes because we need content overrides to be vanilla objects (serializable)

/* a time obj looks like {hour: Number, minutes: Number}
 * hour is 24/military hour time
 * thought there is not limitation, in practice minutes is either 0 or 30
 */

export function isTime(t) {
	function isIntBetween(i, min, max) {
		return Number.isInteger(i) && i >= min && i <= max;
	}
	return isObject(t) && isIntBetween(t.hour, 0, 23) && isIntBetween(t.minutes, 0, 59);
}

/*
 * Time = { hour: Integer, minutes: Integer}
 */
export function Time(hour = null, minutes = null, empty = false) {
	let newTime = { hour, minutes };

	if (isTime(newTime) || empty) {
		return newTime;
	} else {
		throw new TypeError(`${hour} must be int 0-24, ${minutes} must be int 0-60`);
	}
}

/* 1 if a after b
 * 0 if a === b
 * -1 if a before b
 * throws error if a or b are not correct objects
 */
export function compareTime(a, b) {
	if (!isTime(a)) {
		throw new TypeError(`first input given: ${a}, expected instance of Time`);
	}
	if (!isTime(b)) {
		throw new TypeError(`second input given: ${b}, expected instance of Time`);
	}

	if (a.hour === b.hour) {
		if (a.minutes === b.minutes) {
			return 0;
		}
		if (a.minutes > b.minutes) {
			return 1;
		}
		return -1;
	}
	return a.hour > b.hour ? 1 : -1;
}

/*
 * Range = { start: Time, close: Time }
 */
export function Range(sHour = null, sMinutes = null, cHour = null, cMinutes = null) {
	let start = Time(sHour, sMinutes);
	let close = Time(cHour, cMinutes);

	if (!(compareTime(close, start) === 1)) {
		throw new Error('start time not before close time');
	} else {
		return { start, close };
	}
}

// no checks are done
export function EmptyRange() {
	return { start: Time(null, null, true), close: Time(null, null, true) };
}

export function isRange(r) {
	return isObject(r) && isTime(r.start) && isTime(r.close);
}

// true if a overlaps b, false otherwise
//If B starts before A closes AND A starts before B closes return true;
export function overlaps(a, b) {
	if (!isRange(a)) {
		throw new TypeError(`first input given: ${a}, expected {start: Time, close: Time}`);
	}
	if (!isRange(b)) {
		throw new TypeError(`first input given: ${b}, expected {start: Time, close: Time}`);
	}
	const aEndsAfterBstarts = compareTime(a.close, b.start) > -1;

	const bEndsAfterAstarts = compareTime(b.close, a.start) > -1;
	const bEndsBeforeAends = compareTime(b.close, a.close) < 0;

	return (aEndsAfterBstarts || bEndsBeforeAends) && bEndsAfterAstarts;
}

/*
 * remove null Ranges and merge overlaping Ranges
 * Alg from http://www.geeksforgeeks.org/merging-intervals/
 */
export function mergeRanges(ranges) {
	let sortedRanges = ranges
		.filter(r => isRange(r)) // remove incomplete Ranges
		.sort((a, b) => compareTime(a.start, b.start)); // order by start
	let stack = pullAt(sortedRanges, 0);

	sortedRanges.forEach(range => {
		let top = stack[stack.length - 1];
		if (overlaps(top, range)) {
			if (compareTime(range.close, top.close) === 1) {
				//This avoids mutating the original time objects
				stack[stack.length - 1] = { ...top, close: range.close };
			}
		} else {
			stack.push(range);
		}
	});

	return stack;
}

export function timeAsString(time) {
	if (!isTime(time)) {
		throw new TypeError(`input given: ${time}, expected instance of Time`);
	}

	let hour = time.hour;
	let minutes = time.minutes > 0 ? ':' + time.minutes.toString().padStart(2, '0') : '';
	// let minutes = ':' + _.padEnd(time.minutes, 2, '0');

	if (hour === 0) {
		return `${12}${minutes}am`;
	} else if (time.hour === 12) {
		return `${12}${minutes}pm`;
	} else if (time.hour > 12) {
		return `${hour - 12}${minutes}pm`;
	}
	return `${hour}${minutes}am`;
}

export function getTimeInt(timeObj) {
	let hour = timeObj.hour === 0 ? 24 : timeObj.hour;
	let minutes = (timeObj.minutes === 0 ? '' : timeObj.minutes).toString().padStart(2, '0');
	return parseInt(`${hour}${minutes}`);
}

export function defaultDay(name) {
	return { name, openForBusiness: false, times: [Range(9, 0, 17, 0)] };
}

export function convert12hrTo24hr({ hourAs12, ampm }) {
	if (hourAs12 === 12) {
		return ampm === 'pm' ? 12 : 0;
	} else {
		return ampm === 'pm' ? (Number(hourAs12) + 12) % 24 : hourAs12;
	}
}
export function isBelowMinimum({ minimumHour = 0, minimumMinutes = 0, hour = 23, minutes = 59 }) {
	return hour < minimumHour || (minimumHour === hour && minutes < minimumMinutes);
}
export function isAboveMax({ maxHour = 23, maxMinutes = 59, hour = 0, minutes = 0 }) {
	return hour > maxHour || (maxHour === hour && minutes > maxMinutes);
}
export var timezones = {
	'America/Anchorage': 'Alaska',
	'America/Chicago': 'Central',
	'America/New_York': 'Eastern',
	'Pacific/Honolulu': 'Hawaii',
	'America/Denver': 'Mountain',
	'America/Los_Angeles': 'Pacific',
	'America/Phoenix': 'Arizona',
};
