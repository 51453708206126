/* eslint-env browser */
import { extend } from 'lodash';
export function host(url) {
	const h = url.replace(/^https?:\/\//, '').replace(/\/.*$/, '');
	const parts = h.split('.').slice(-3);
	if (parts[0] === 'www') {
		parts.shift();
	}
	return parts.join('.');
}

export function readMetaTags() {
	const mt = {};
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		const metas = document.getElementsByTagName('meta');
		for (let i = 0; i < metas.length; i++) {
			const name = metas[i].getAttribute('name');
			if (name) {
				mt[name] = metas[i].getAttribute('content');
			}
		}
	}
	return mt;
}

export function mysfAuthUrl() {
	const { mysf } = querystring();

	const prefix = mysf ? `${mysf}.` : '';
	return `https://${prefix}mysfdomain.com`;
	// return {
	//     'local'     : 'https://local.mysfdomain.com/',
	//     'old-test'  : 'https://local.mysfdomain.com/',
	// }[querystring().mysf] || '';
}

export function paraFormat(text) {
	const tokens = {
		list: /^\s*\*|-/,
		// 'header' : /^\s*#/,
	};

	let parts = text.split(/\r?\n\r?\n\r?/g).filter(Boolean);
	parts = parts
		.map(p => {
			const lines = p.split(/\r?\n/g);

			let blocks = [];
			let cblock = {
				type: null,
				lines: [],
			};
			lines.forEach(line => {
				const char = line.trim()[0];
				let ctype;
				Object.entries(tokens).some(([key, value]) => {
					if (value.test(char)) {
						ctype = key;
						line = line.replace(value, '').trim();
						//stop iterating
						return true;
					}
				});
				if (!ctype && line.length > 0) {
					ctype = 'para';
				}
				// new block
				if (ctype !== cblock.type) {
					if (cblock.lines.length === 0) {
						cblock.type = ctype;
					} else {
						blocks.push(cblock);
						cblock = {
							type: ctype,
							lines: [],
						};
					}
				}
				cblock.lines.push(line);
			});
			blocks.push(cblock);
			return blocks;
		})
		.reduce((a, b) => a.concat(b), []);

	const rebuilt = parts
		.map(p => {
			if (p.type === 'para') {
				let t = p.lines.join('<br>');

				return `<p>${t}</p>`;
			} else if (p.type === 'list') {
				return `<ul>${p.lines.map(l => `<li>${l}</li>`).join('')}</ul>`;
			} else if (p.type === 'header') {
				return `<h4>${p.lines.join(' ')}</h4>`;
			}
		})
		.join(' ');

	return rebuilt;
	// replace multiple linebreaks with paragraphs
}

export function timeAgo(time) {
	const between = (new Date().getTime() - Number(time)) / 1000;

	if (between < 3600) {
		return pluralize(~~(between / 60), ' min');
	} else if (between < 86400) {
		return pluralize(~~(between / 3600), ' hr');
	} else {
		return pluralize(~~(between / 86400), ' day');
	}
}

export function associatePhotoUrl(srcUrl, decoration = '', prefix = '') {
	if (!srcUrl) {
		return '/not/found/';
	}
	function splitter([type, starts]) {
		if (srcUrl.indexOf(starts) !== 0) {
			return false;
		}
		const [path, ext] = srcUrl.replace(starts, '').split(/\.(?=[^.]+$)/);
		return {
			type,
			path,
			ext,
		};
	}

	const cleanDecoration = (decoration || '').replace(/\./g, '').replace(/\s+/g, '-');
	const { type, path, ext } =
		[
			['default', '/assocphoto/'],
			['uploaded', 'https://s3.amazonaws.com/nakala.mirus.io/m1/approvals/accepted/'],
			['queue', 'https://s3.amazonaws.com/nakala.mirus.io/m1/approvals/queue/'],
		]
			.map(splitter)
			.filter(Boolean)[0] || {};

	// for pending images just use the source url
	const url =
		type === 'queue'
			? srcUrl
			: `${prefix}/associate-photos/${type}/${path}__${cleanDecoration}.${ext}`;
	return url;
}

function pluralize(time, label) {
	if (time === 1) {
		return time + label;
	}
	return time + label + 's';
}

export function phone(pn) {
	if (!Array.isArray(pn)) {
		return {};
	}

	function alpha_cleaner(p) {
		p = p.replace(/[\W_]+/g, ''); // remove non-alphanumerics

		if (p.match(/[a-zA-Z]+/gi)) {
			p = p.replace(/[abc]/gi, '2');
			p = p.replace(/[def]/gi, '3');
			p = p.replace(/[ghi]/gi, '4');
			p = p.replace(/[jkl]/gi, '5');
			p = p.replace(/[mno]/gi, '6');
			p = p.replace(/[pqrs]/gi, '7');
			p = p.replace(/[tuv]/gi, '8');
			p = p.replace(/[wxyz]/gi, '9');
		}
		return p;
	}
	let nums = {};
	// each(
	pn.map(d => {
		nums[d.type] = nums[d.type] || [];
		const n = d.number.toUpperCase();
		nums[d.type].push({
			raw: n,
			digits: alpha_cleaner(n),
			pretty: phoneFormat(n),
			prettydigits: d.number.match(/[a-zA-Z]+/gi) ? phoneFormat(alpha_cleaner(n)) : null,
		});
	});
	return nums;
}

export function phoneFormat(ph) {
	ph = ph.toString();
	//Removes the following characters ' ', '(', ')', '-', '+'
	ph = ph.replace(/[\s+()-]+/gi, '');
	const numericRegExp = new RegExp('^[0-9]+$');

	if (numericRegExp.test(ph)) {
		if (ph.length === 11) {
			ph = '+1 (' + ph.substring(1, 4) + ') ' + ph.substring(4, 7) + '-' + ph.substring(7);
		} else if (ph.length === 10) {
			ph = '(' + ph.substring(0, 3) + ') ' + ph.substring(3, 6) + '-' + ph.substring(6);
		}
	}
	return ph;
}

export function textbreak(rawtext, threshold = 150) {
	if (!rawtext) {
		return {};
	}

	const excessive = rawtext.length > threshold * 1.05;
	const breakon = ['.', ',', ' ', '?', '!'];
	if (!excessive) {
		return {
			less: rawtext,
		};
	}
	const lefty = rawtext.substring(0, threshold - 1);
	const righty = rawtext.substring(threshold);
	const lmax = Math.max(
		...breakon
			.map(c => {
				let idx = lefty.lastIndexOf(c);
				return idx < 0 ? null : idx;
			})
			.filter(Boolean)
	);
	const rmin =
		threshold +
			Math.min(
				...breakon
					.map(c => {
						let idx = righty.indexOf(c);
						return idx < 0 ? null : idx;
					})
					.filter(Boolean)
			) || rawtext.length;
	function spliton(idx) {
		return {
			less: rawtext.substring(0, idx),
			more: rawtext,
		};
	}
	if (lmax && !rmin) {
		return spliton(lmax);
	}
	if (rmin && !lmax) {
		return spliton(rmin);
	}

	return spliton(threshold - lmax < rmin - threshold ? lmax : rmin);
}

function querystring(query) {
	return querystring.decode(query);
}

querystring.decode = function (q) {
	q = q || window.location.search;
	if (q === '' || typeof q === 'undefined') {
		return {};
	}
	return (/^[?#]/.test(q) ? q.slice(1) : q).split('&').reduce((params, param) => {
		let [key, value] = param.split('=');
		params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
		return params;
	}, {});
};

querystring.encode = function (o, Qmark = false) {
	let a = [];
	for (let k in o) {
		let v = o[k].constructor === Array ? o[k].join(',') : o[k];
		a.push(`${encodeURIComponent(k)}${v ? '=' + encodeURIComponent(v) : ''}`);
	}
	return `${Qmark ? '?' : ''}${a.join('&')}`;
};

querystring.extend = function (o, Qmark = false) {
	const base = querystring.decode();
	const extended = extend(base || {}, o);
	return querystring.encode(extended, Qmark);
};

export { querystring };
