/* eslint-env browser */

import Keen from 'keen-tracking';
import { readMetaTags } from './filters';

function extender() {
	const meta = readMetaTags();
	return {
		__passthrough: meta.peachy || null,
		backend: 'mysfdomain',
		product_type: 'mysfdomain-ui',
		codebase: {
			version: meta.version || null,
			branch: meta.branch || null,
		},
	};
}

const plugin = {
	install: function (Vue) {
		if (typeof window === 'undefined') {
			return; // ssr escape
		}
		const host = import.meta.env.VUE_APP_LOCAL_PEACHY_HOST || `peachy.prod.mirus.io/record`;
		const client = new Keen({
			host,
			projectId: 'foo',
			writeKey: 'bar',
			protocol: import.meta.env.VUE_APP_LOCAL_PEACHY_HOST ? 'http' : `https`,
			requestType: 'xhr', // makes testing a lot easier
		});

		Vue.prototype.$peachy = function (...args) {
			return client.recordEvent(...args);
		};

		client.extendEvents(extender);
		client.initAutoTracking({
			ignoreDisabledFormFields: false,
			ignoreFormFieldTypes: ['password'],
			recordClicks: true,
			recordFormSubmits: true,
			recordPageViews: true,
			recordScrollState: true,
		});
	},
};

export default plugin;
